<template>
  <section class="content">
    <div class="wrap">
      <h2>404</h2>
      <p>
        Щось пішло не так! Сторінку, яку ви шукаєте, не існує. Поверніться на
        <span
          class="link"
          @click="$router.go(-1)"
        >попередню сторінку</span> або на
        <router-link class="link" to="/">головну</router-link>.
      </p>
    </div>
  </section>
</template>
<script>
export default {
  name: "404"
};
</script>
<style scoped>
section.content {
  width: 100%;
  height: auto;
  background: #e4e4e4;
}

.wrap {
  width: calc(100% - 30px);
  max-width: 1250px;
  height: calc(60vh - 45px);
  margin: 0 auto;
  padding: 40px 0;
}

h2 {
  margin: 30px auto;
  font-size: 62px;
}

p {
  font-size: 20px;
  max-width: 650px;
  margin: auto;
}

p .link {
  color: #5ba8cc;
  cursor: pointer;
}

@media screen and (max-width: 700px) {
  .wrap {
    height: calc(70vh - 45px);
  }
}
</style>